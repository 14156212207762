/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from '@emotion/styled'
import { ApolloError, useMutation } from '@graphcommerce/graphql'
import { Image } from '@graphcommerce/image'
import { i18n } from '@lingui/core'
import {
  Box,
  CircularProgress,
  LinearProgress,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import axios from 'axios'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import uploadCompleteIcon from '../../assets/images/upload-complete.svg'
import { DetachArtworkDocument } from '../Artwork/DetachArtwork.gql'
import { GenerateSignedCommandUrlDocument } from '../Artwork/GenerateSignedCommandUrl.gql'
import { TertiaryButton, StandardButton } from '../Button/ButtonStyles'
import { Icon } from '../Layout/Icon'
import { ErrorPrompt } from '../Prompt/ErrorPrompt'

export type s3File = {
  url?: string
  fileName?: string
  size?: number
  uploadPercentage?: number
  artworkId?: string
}

type FileUploadModalProps = {
  isDiplayed: boolean
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
  setUploadedFile: (files: any, type?: string) => void
  modalId: string
  files: s3File[]
  type?: string
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 5,
  backgroundColor: '#D1D3D4',
}))

export function FileUploadModal(props: FileUploadModalProps) {
  const { isDiplayed, setIsDisplayed, setUploadedFile, modalId, files, type } = props
  const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false)
  const [localFiles, setLocalFiles] = useState<Array<s3File>>(files)
  const [filesToDetach, setFilesToDetach] = useState<Array<s3File>>([])
  const [disableUpload, setDisableUpload] = useState<boolean>(localFiles?.length <= 0)
  const inputFilesRef = useRef(null)
  const [progress, setProgress] = useState<number>(0)
  const [error, setError] = useState<Error>()
  const handleClose = () => setIsDisplayed(false)
  const [detachArtwork] = useMutation(DetachArtworkDocument, {
    refetchQueries: ['CartPage', 'ShippingPage', 'PaymentPage'],
  })

  const [generateSignedCommandUrl] = useMutation(GenerateSignedCommandUrlDocument)

  const sendPOST = async (body: any, url: string) => {
    const rawResponse = await fetch(url, {
      method: 'PUT',
      body,
    }).catch((e: Error) => {
      setError(e)
      setIsUploadingFiles(false)
      setProgress(0)
    })

    await axios
      .request({
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'PUT',
        url,
        data: body,

        onUploadProgress: (p) => {
          const total = p?.total ?? p.bytes
          setProgress(Math.floor((p.loaded * 100) / total))
        },
      })
      .catch((e: Error) => {
        setError(e)
        setIsUploadingFiles(false)
        setProgress(0)
        return false
      })
    setProgress(0)
    return url?.substring(0, rawResponse?.url?.indexOf('?'))
  }

  const handleSetArtworkFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploadingFiles) return

    setProgress(0)
    setIsUploadingFiles(true)
    const localUploadedFiles = [...localFiles]

    if (event?.target?.files) {
      const tempS3File = {
        url: '',
        fileName: event?.target?.files[0]?.name,
        size: event?.target?.files[0]?.size,
        uploadPercentage: progress,
      }
      localUploadedFiles.push(tempS3File)
      setLocalFiles(localUploadedFiles)

      const s3FileName = `artworks/${Date.now()}-${event?.target?.files[0]?.name}`
      const signedUrl = await generateSignedCommandUrl({
        variables: { objectKey: s3FileName, bucket: 'artwork' },
      }).catch((e: ApolloError) => {
        setError(e)
        setIsUploadingFiles(false)
        setProgress(0)
      })
      if (signedUrl) {
        const result = await sendPOST(
          event?.target?.files[0],
          signedUrl.data?.generateSignedCommandUrl?.url ?? '',
        )
        if (!result) {
          return
        }
        const newS3File = {
          url: result,
          fileName: event?.target?.files[0]?.name,
          size: event?.target?.files[0]?.size,
          uploadPercentage: 100,
        }
        localUploadedFiles.pop()
        localUploadedFiles.push(newS3File)
        setIsUploadingFiles(false)
        setLocalFiles(localUploadedFiles)
      }
    }
  }

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    if (isUploadingFiles) return
    event.preventDefault()

    if (event?.dataTransfer?.files) {
      const filesArray = Array.from(event?.dataTransfer?.files)
      const localUploadedFiles = [...localFiles]
      for (let idx = 0; idx < filesArray.length; idx++) {
        setProgress(0)
        setIsUploadingFiles(true)
        const tempS3File = {
          url: '',
          fileName: filesArray[idx]?.name,
          size: filesArray[idx]?.size,
          uploadPercentage: progress,
        }
        localUploadedFiles.push(tempS3File)
        setLocalFiles(localUploadedFiles)
        const s3FileName = `artworks/${Date.now()}-${filesArray[idx].name}`
        // eslint-disable-next-line no-await-in-loop
        const signedUrl = await generateSignedCommandUrl({
          variables: { objectKey: s3FileName, bucket: 'artwork' },
        }).catch((e: ApolloError) => {
          setError(e)
          setIsUploadingFiles(false)
        })

        if (signedUrl) {
          // eslint-disable-next-line no-await-in-loop
          const result = await sendPOST(
            filesArray[idx],
            signedUrl.data?.generateSignedCommandUrl?.url ?? '',
          )
          if (!result) {
            return
          }
          const newS3File = {
            url: result,
            fileName: filesArray[idx].name,
            size: filesArray[idx]?.size,
            uploadPercentage: 100,
          }
          localUploadedFiles.pop()
          localUploadedFiles.push(newS3File)
          setLocalFiles(localUploadedFiles)

          if (idx === filesArray.length - 1) {
            setIsUploadingFiles(false)
          }
        }
      }
    }
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleRemoveItem = (index: number) => {
    const localFilesCopy = [...localFiles]
    setLocalFiles(localFilesCopy.filter((item, idx) => idx !== index))

    const localFileToRemove = localFilesCopy.filter((item, idx) => idx === index)?.at(0)
    if (localFileToRemove?.artworkId) {
      // detachArtwork({
      //   variables: {
      //     artworkId: localFileToRemove.artworkId,
      //   },
      // }).catch((errorDetatch: Error) => {
      //   setError(errorDetatch)
      //   setLocalFiles(localFilesCopy)
      // })
      setFilesToDetach([...filesToDetach, localFileToRemove])
    }
  }

  const handleDetachFiles = async () => {
    // eslint-disable-next-line array-callback-return
    filesToDetach.map((localFileToRemove) => {
      if (localFileToRemove?.artworkId) {
        detachArtwork({
          variables: {
            artworkId: localFileToRemove.artworkId,
          },
        }).catch((errorDetatch: Error) => {
          setError(errorDetatch)
          return Promise.reject(errorDetatch)
        })
      }
    })
    return Promise.resolve('Success!')
  }

  useEffect(() => {
    setDisableUpload(localFiles?.length <= 0)
  }, [localFiles])

  useEffect(() => {
    setLocalFiles(files)
  }, [files, isDiplayed])

  return (
    <Modal className='z-10 overflow-y-auto' id={modalId} open={isDiplayed} onClose={handleClose}>
      <div className='absolute left-[5vw] top-[5vh] w-[90vw] max-w-[750px] rounded-md border-solid bg-pure-white md:left-[25vw] md:w-[50vw] lg:left-[25vw]'>
        <span className='Type-XL-Medium md:Type-XXL-Medium mx-5 mt-5 flex items-center justify-center text-center italic lg:mt-10'>
          {i18n._('A proof will be sent after we receive your order')}
        </span>
        <div
          className='mt-5 flex flex-col items-center justify-center lg:mt-10 '
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <label
            className={`max-w-3/4 flex h-40 w-3/4 cursor-pointer flex-col items-center justify-center rounded-md border-2 border-dashed border-material-ui-blue bg-material-ui-blue/10 px-4 transition hover:border-material-ui-dark-blue focus:outline-none ${
              isUploadingFiles ? 'bg-light-gray hover:border-medium-gray' : ''
            }`}
          >
            <div
              className={`${
                isUploadingFiles ? 'hidden' : 'flex flex-col items-center justify-center'
              }`}
            >
              <Icon name='upload_file' className='align-middle text-3xl text-material-ui-blue' />
              <span className='mt-2 flex items-center space-x-2'>
                <span className='text-gray-600 text-center font-medium'>
                  <span className='text-material-ui-blue underline'>
                    {i18n._('Click to upload')}
                  </span>
                  {i18n._(' or drag and drop')}&nbsp;
                </span>
              </span>
              <span className='Type-Base-Regular mt-5 text-70-grey'>
                {i18n._('PDF, AI, SVG or JPG (max. 20MB)')}
              </span>
            </div>
            <div className={`${isUploadingFiles ? 'flex items-center justify-center' : 'hidden'}`}>
              <span>Uploading files. Please wait.</span>
            </div>
            <input
              type='file'
              name='file_upload'
              className='hidden'
              ref={inputFilesRef}
              onChange={handleSetArtworkFiles}
              onDrop={handleDrop}
              disabled={isUploadingFiles}
            />
          </label>
          <div className='mb-10 w-full'>
            <div className='mb-5 mt-5 flex max-h-[255px] w-full flex-col items-center justify-start overflow-y-auto'>
              <div className='mt-2 flex w-full flex-col items-center justify-start gap-3 lg:mt-5'>
                {localFiles.length > 0 &&
                  localFiles?.map((item, index) => (
                    <Paper
                      elevation={3}
                      key={item.url}
                      className='lg:Type-Large-Medium Type-Regular-Medium w-3/4 items-center justify-center border-[1px] border-lightest-gray py-4'
                    >
                      <div className='flex w-full max-w-full items-center justify-between'>
                        <div className='flex items-center justify-between gap-6'>
                          <Icon
                            name='upload_file'
                            className='ml-5 align-middle text-3xl text-material-ui-blue'
                          />
                          <div className='flex flex-col items-start justify-center gap-1'>
                            <Tooltip title={item.fileName}>
                              <a
                                href={item.url}
                                target='_blank'
                                rel='noreferrer'
                                className='Type-Large-Medium'
                              >
                                <span className='block max-w-[80px] truncate whitespace-nowrap md:max-w-[100px] lg:max-w-[200px] xl:max-w-[280px] 2xl:max-w-[350px]'>
                                  {item.fileName}
                                </span>
                              </a>
                            </Tooltip>
                            {item?.size && (
                              <span className='Type-Base-Regular text-70-grey'>
                                {`${Math.floor((item.size ?? 0) / 1024 ** 1)}kb`}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='mr-5 flex items-center justify-between gap-7'>
                          <Icon
                            name='delete'
                            className={`cursor-pointer align-middle text-xl lg:text-3xl ${
                              isUploadingFiles
                                ? 'text-light-gray'
                                : 'text-black hover:text-material-ui-blue'
                            }`}
                            onClick={() => {
                              if (!isUploadingFiles) {
                                handleRemoveItem(index)
                              }
                            }}
                            disabled={isUploadingFiles}
                          />
                          {isUploadingFiles && (item.uploadPercentage ?? 0) < 100 && (
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                              <CircularProgress variant='determinate' value={progress} size={40} />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: 'absolute',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography
                                  variant='caption'
                                  component='div'
                                  color='text.secondary'
                                >{`${Math.round(progress)}%`}</Typography>
                              </Box>
                            </Box>
                          )}
                          {!item.artworkId && item.uploadPercentage === 100 && (
                            <Image
                              src={uploadCompleteIcon}
                              unoptimized
                              height={10}
                              className='ml-4'
                            />
                          )}
                        </div>
                      </div>
                    </Paper>
                  ))}
              </div>
            </div>
            <div className='mt-10 flex w-full flex-col items-center justify-center gap-6 md:mt-0 md:flex-row md:gap-10'>
              <TertiaryButton
                type='button'
                className='w-[90%] max-w-[350px] bg-[#F2F2F2] uppercase lg:w-[200px]'
                color='secondary'
                variant='contained'
                size='small'
                onClick={() => {
                  setIsDisplayed(false)
                  setError(undefined)
                }}
                disabled={isUploadingFiles}
              >
                {i18n._(/* i18n */ 'Cancel')}
              </TertiaryButton>
              {localFiles && localFiles?.length > 0 && (
                <StandardButton
                  type='button'
                  color={localFiles?.length > 0 ? 'primary' : 'info'}
                  className='w-[90%] max-w-[350px] bg-material-ui-blue uppercase lg:w-[200px]'
                  disabled={disableUpload || isUploadingFiles}
                  variant='contained'
                  size='small'
                  onClick={async () => {
                    setIsDisplayed(false)
                    setUploadedFile(localFiles, type)
                    setError(undefined)
                    setLocalFiles([])
                    await handleDetachFiles()
                    setFilesToDetach([])
                  }}
                >
                  {i18n._(/* i18n */ 'Proceed')}
                </StandardButton>
              )}
            </div>
          </div>
        </div>
        <ErrorPrompt error={error} />
      </div>
    </Modal>
  )
}
